

















































import { Vue, Component } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import { getCountries, getCountryCallingCode } from 'libphonenumber-js'

interface CountryCode {
  countryCodeText: string;
  countryCodeValue: string;
}

@Component({
  computed: {
    ...mapGetters(['translate'])
  }
})
export default class PhoneInput extends Vue {
  COUNTRY_CODES_WITHOUT_AREA_CODE = ['+351', '+34']
  countryCode = ''
  areaCode = ''
  number = ''
  translate!: (key: string) => string

  get shouldShowAreaCode (): boolean {
    return !this.COUNTRY_CODES_WITHOUT_AREA_CODE.includes(this.countryCode)
  }

  phoneChange (): void {
    const phone = this.areaCode ? `${this.countryCode} (${this.areaCode}) ${this.number}` : `${this.countryCode} ${this.number}`
    this.$emit('input', phone)
    this.validatePhone()
  }

  get countryCodes (): CountryCode[] {
    return getCountries().map<CountryCode>((countryCode) => {
      const callingCode = `+${getCountryCallingCode(countryCode)}`

      return {
        countryCodeText: callingCode,
        countryCodeValue: callingCode
      }
    })
  }

  get areaCodeRules (): ((v: string) => boolean | string)[] {
    return [
      (v: string) => this.COUNTRY_CODES_WITHOUT_AREA_CODE.includes(this.countryCode) || !!v || this.translate('input.errors.requiredAreaCode'),
      (v: string) => !v || /^\d{1,6}$/.test(v) || this.translate('input.errors.invalidAreaCode')
    ]
  }

  get countryCodeRules (): ((v: string) => boolean | string)[] {
    return [
      (v: string) => !!v || this.translate('input.errors.requiredCountryCode')
    ]
  }

  get numberRules (): ((v: string) => boolean | string)[] {
    return [
      (v: string) => !!v || this.translate('input.errors.requiredPhone'),
      (v: string) => /^\d{1,16}$/.test(v) || this.translate('input.errors.invalidPhone')
    ]
  }

  validatePhone (): void {
    const isCountryCodeValid = this.countryCodeRules.every(rule => rule(this.countryCode.replace('+', '')) === true)
    const isAreaCodeValid = this.COUNTRY_CODES_WITHOUT_AREA_CODE.includes(this.countryCode) || this.areaCodeRules.every(rule => rule(this.areaCode) === true)
    const isNumberValid = this.numberRules.every(rule => rule(this.number) === true)

    this.$emit('validate', isCountryCodeValid && isAreaCodeValid && isNumberValid)
  }
}
