import moment from '@/plugins/moment'

export const locale = () : string =>
  Intl.DateTimeFormat().resolvedOptions().locale

export const dateToText = (date: Date) : string =>
  new Intl.DateTimeFormat(locale(), { dateStyle: 'long' } as Intl.DateTimeFormatOptions).format(date)

export const dateToTextLong = (date: Date) : string =>
  new Intl.DateTimeFormat(locale(), { dateStyle: 'full' } as Intl.DateTimeFormatOptions).format(date)

export const dateToIso = (date: Date) : string =>
  moment(date).format('YYYY-MM-DD')

export const dateFormat = (): string =>
  locale() === 'en-US' ? 'MM/DD/YYYY' : 'DD/MM/YYYY'

export const textToDate = (text: string) : Date =>
  moment(text, dateFormat()).toDate()
